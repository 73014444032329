/*** IMPORTS FROM imports-loader ***/
var define = false;

import { TimelineMax } from "gsap";

export default {
    init: (app) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $postsContainer    = $('.loaded-references'),
            $blocFilters       = $('.bloc-filters'),
            $itemFilter        = $blocFilters.find('.item-filter'),
            $loaderFilter      = $('#loader-filter')
        ;
        
        /*
        |
        | Variables
        |------------
        */
        let 
            xhr   = null,
            state = {
                secteurId: 'all',
                secteurName: null
            }
        ;

        /*
        |
        | Variables
        |------------
        */
        $('body').on('mouseover', '.item-reference', function(){
            const $item = $(this);
            const $descr = $item.find('.item-descr')

            if($(window).width() > 1200){
                $descr.slideDown(400, function () {
                    app.updateLocomotiveScroll()
                })
            }
            
        }).on('mouseleave', '.item-reference', function () {
            const $item = $(this);
            const $descr = $item.find('.item-descr')

            if ($(window).width() > 1200) {
                $descr.slideUp(400, function () {
                    app.updateLocomotiveScroll()
                })
            }
        });


        /*
        |
        | Filtering
        |-----------
        */
        $itemFilter.on('click', function(e){
            e.preventDefault();

            const $item = $(this);

            $itemFilter.removeClass('active');
            $item.addClass('active');

            state.secteurId = $item.data('secteur-id');
            state.secteurName = $item.find('.item-text').text();

            ajaxFilter();
        });


        function ajaxFilter(){
            if (xhr !== null) {
                xhr.abort()
            }

            console.log(state)

            const route = `/ajax/references/secteur/${state.secteurId}`;

            xhr = $.ajax({
                url: route,
                type: 'POST',
                data: { secteur: state.secteurName },
                dataType: 'json',
                beforeSend: () => {
                    $postsContainer.fadeOut(400, function () {
                        $(this).html('');
                        $loaderFilter.fadeIn(400);
                    });
                },
                success: (response, status) => {
                    $postsContainer.html(response).show();
                    const tl = new TimelineMax({
                        paused: true,
                        onComplete: () => {
                            app.updateLocomotiveScroll()
                        }
                    })
                    tl.staggerFrom($postsContainer.find('.item-reference.loaded'), 0.8, {
                        autoAlpha: 0,
                        y: 30,
                        ease: Power1.easeOut
                    }, 0.1);
                    tl.play();
                    $postsContainer.find('.item-reference.loaded').removeClass('loaded')
                    $loaderFilter.hide();
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(response, status, error);
                }
            })
        }
    }
}
