/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $body                = $('body'),
            $sectionFigures      = $('#section-figures'),
            $expertisesItem      = $sectionFigures.find('.item-expertise'),
            $sectionCaseStudies  = $('#section-case-studies'),
            $swiperCaseStudies   = $('.swiper-case-studies'),
            $sectionOrganizations = $('#section-organizations'),
            $swiperOrganizations  = $sectionOrganizations.find('.swiper-organizations')
        ;

        $body.on('loader:end', () => {
            $('.ff-type-all').trigger('click')
        })

        $('body').on('click', '.ff-filter', function () {
            app.updateLocomotiveScroll()
        })

        let timeoutId = null;

        timeoutId = setInterval(() => {
            if ($('.ff-item').length) {
                console.log('loaded');
                app.updateLocomotiveScroll();
                clearInterval(timeoutId);
            } else {

            }
        }, 500);


        $('body').on('click', '.ff-loadmore-wrapper .ff-btn', function () {
            setTimeout(() => {
                app.updateLocomotiveScroll();
            }, 3000);
        })

        /*
        |
        | Expertises défilement
        |------------------------
        */
        const tlExpertises = new TimelineMax({paused: true, repeat: -1})

        $.each($expertisesItem, function() {
            const $item = $(this);
            tlExpertises.from($item, 0.6, { y: 30, opacity: 0, ease: Sine.easeOut })
            tlExpertises.to($item, 0.6, { y: -30, opacity: 0, ease: Sine.easeOut }, '+=1')
        });

        tlExpertises.play();
          
 
    
        /*
        |
        | Swiper Case study 
        |--------------------
        */
        const swiperCaseStudy = new Swiper($swiperCaseStudies.find('.swiper-container'), {
            slidesPerView: 3,
            speed: 1000,
            spaceBetween: 0,
            navigation: {
                prevEl: $('.swiper-case-studies-arrows').find('.arrow-left'),
                nextEl: $('.swiper-case-studies-arrows').find('.arrow-right')
            },
            breakpoints: {
                1: {
                    slidesPerView: 1,
                },
                992: {
                    slidesPerView: 2,
                },
                1200: {
                    slidesPerView: 3,
                }
            }
        })


        /*
        |
        | Swiper Organizations
        |---------------------
        */
        const orgOptions = {
            slidesPerView: 4,
            speed: 1000,
            spaceBetween: 0,
            navigation: {
                prevEl: $('.swiper-organizations-arrows').find('.arrow-left'),
                nextEl: $('.swiper-organizations-arrows').find('.arrow-right')
            },            
            // pagination: {
            //     el: '.swiper-organizations-pagination',
            //     type: 'progressbar',
            //     clickable: true
            // },
            breakpoints: {
                1: {
                    slidesPerView: 1.2,
                },
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 4,
                }
            }
        }

        if ($swiperOrganizations.find('.swiper-slide').length > 4) {
            orgOptions.scrollbar = {
                el: '.swiper-organizations-pagination',
                draggable: true
            }
        }

        const swiperOrganizations = new Swiper($swiperOrganizations.find('.swiper-container'), orgOptions);        
        
    }
}
