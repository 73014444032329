/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, MaterializeForm) => {
        /*
        |
        | Constants
        |------------
        */
        const
            $body = $('body'),
            $map = $('#map'),
            $contactForm = $('.contact-form'),
            $itemLocation = $('.item-location'),
            isMobile = $body.hasClass('isMobile')
        ;


        /*
		|
		| initGoogleMap
		|----------------
        */


        const event = isMobile ? 'click' : 'mouseover';
        $itemLocation.on(event, function () {
            const $item = $(this);
            const lat = $item.data('lat')
            const lng = $item.data('lng')

            $itemLocation.removeClass('active');
            $item.addClass('active');  

            if($(window).width() < 1200){
                app.scrollTo(document.getElementById('map'), -100)
            }

            map.googleMap.panTo({lat: lat, lng: lng})
        });

        $('.item-location.active').trigger(event)

    }
}
