/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|--------------------------------------------------------------------------------
|                                  Custom Google Map
|--------------------------------------------------------------------------------
|
| Scroller is a lightweight library to manage "animated & looped" sliding contents
| It uses Greensock as main dependency
|
*/

/*
|
| Class
|--------
|
*/
class customGoogleMap {
    /*
    |
    | Constructor
    |--------------
    */
    constructor($map, params) {
        this.map = $map;
        this.params = params;
        this.init();
        this.googleMap;
    }

    /*
    |
    | init
    |-------
    */
    init() {

        var _this = this;
        _this.googleMap = new google.maps.Map(_this.map[0], {
            zoom: 1,
            scrollwheel: false,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: this.getCustomization(),
            disableDefaultUI: this.params.disableDefaultUI || false,
            zoomControl: this.params.disableDefaultUI || true,
        });

        _this.googleMap.markers = [];

        $.each(this.params.markers, function () {
            _this.add_marker($(this), _this.googleMap);
        });
        _this.center_map(_this.googleMap);

        return _this.googleMap;
    }

    /*
    |
    | add_marker
    |-------------
    */
    add_marker($marker, map) {
        var _this = this;
        var latlng = new google.maps.LatLng($marker.data('lat'), $marker.data('lng'));

        var icon = {
            url: $marker.data('icon'),
            scaledSize: new google.maps.Size(24, 36),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(12, 36)
        };

        console.log($marker.data('zindex'))

        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: icon,
            zIndex: $marker.data('zindex')
        });

        map.markers.push(marker);

        if (this.params.customInfoWindow === true) {
            google.maps.event.addListener(marker, 'click', function (e) {
                $('.marker').not($marker).removeClass('active')

                $marker.addClass('active');

                if (_this.params.scrollToCard) {
                    if ($marker.hasClass('active')) {                       
                        if (_this.params.onMarkerClicked){
                            const fn = _this.params.onMarkerClicked;
                            fn.call();
                        }
                        
                    }
                }
            });

            // google.maps.event.addListener(map, 'click', function (e) {
            //     $('.marker').removeClass('active')
            // });
        } else {
            if ($marker.html()) {
                var infowindow = new google.maps.InfoWindow({
                    content: '<div class="map-card">' + $marker.html() + '</div>'
                });
            }

            google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker);
            });
        }
    }

    /*
    |
    | center_map
    |-------------
    */
    center_map(map) {
        var bounds = new google.maps.LatLngBounds();

        $.each(map.markers, function (i, marker) {
            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

            bounds.extend(latlng);
        });



        if (map.markers.length == 1) {
            map.setCenter(bounds.getCenter());
            map.setZoom(this.params.zoom);
        } else {
            if (!this.params.noFitBounds) {
                map.fitBounds(bounds);
                if(this.params.fitBoundsZoom){
                    const _this = this;
                    var listener = google.maps.event.addListener(map, "idle", function () {
                        if (map.getZoom() > _this.params.fitBoundsZoom) map.setZoom(_this.params.fitBoundsZoom);
                        google.maps.event.removeListener(listener);
                    });
                }
                
            } else {
                map.setZoom(this.params.zoom);
            }
           
            
        }

    }

    /*
    |
    | getCustomization
    |-------------------
    */
    getCustomization() {
        return [{
                "elementType": "geometry",
                "stylers": [{
                    "color": "#f5f5f5"
                }]
            },
            {
                "elementType": "labels.icon",
                "stylers": [{
                    "visibility": "off"
                }]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#616161"
                }]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [{
                    "color": "#f5f5f5"
                }]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#bdbdbd"
                }]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [{
                    "color": "#eeeeee"
                }]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#757575"
                }]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [{
                    "color": "#e5e5e5"
                }]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#9e9e9e"
                }]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [{
                    "color": "#ffffff"
                }]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#757575"
                }]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [{
                    "color": "#dadada"
                }]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#616161"
                }]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#9e9e9e"
                }]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [{
                    "color": "#e5e5e5"
                }]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [{
                    "color": "#eeeeee"
                }]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [{
                    "color": "#efefef"
                }]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#9e9e9e"
                }]
            }
        ]
    }
}

export default customGoogleMap;
