/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper, customGoogleMap) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $body = $('body'),
												$sectionExpertises = $('#section-expertises'),
												$map                = $('#partners-map'),
            $expertiseTitle    = $sectionExpertises.find('.item-expertise-title'),
            isMobile = $body.hasClass('isMobile'),
            $sectionPoles = $('#section-pole-news'),
            $swiperPoles  = $sectionPoles.find('.swiper-poles'),
            $sectionCases = $('#section-case-studies'),
            $swiperCases  = $sectionCases.find('.swiper-cases')
        ;

        /*
		|
		| initGoogleMap
		|----------------
		|
        */
							const toHide = document.querySelector('.map-hide')

							$.each($map, function () {
											var $map = $(this);
											console.log($('.marker').length)
											var map = new customGoogleMap($map, {
															'markers': $('.marker'),
															'zoom': 4,
															'fitBoundsZoom': 5,
															'customInfoWindow': true,
															'scrollToCard': true,
															'disableDefaultUI': true,
															'onMarkerClicked': function () {
																			toHide.classList.add('active');

																			if($(window).width() < 1200){
																							app.scrollTo(document.querySelector('.trigger-marker'))
																			}
																			setTimeout(() => {
																							
																			}, 1000);
																			app.updateLocomotiveScroll()
															}
											});
							});


								/*
        |
        | Swiper Cases
        |---------------------
        */
        const orgOptions = {
									slidesPerView: 4,
									speed: 1000,
									spaceBetween: 0,
									navigation: {
										prevEl: $('.swiper-cases-arrows').find('.arrow-left'),
										nextEl: $('.swiper-cases-arrows').find('.arrow-right')
									},   
									breakpoints: {
													1: {
																	slidesPerView: 1.2,
													},
													768: {
																	slidesPerView: 2,
													},
													992: {
																	slidesPerView: 4,
													},
													1200: {
																	slidesPerView: 4,
													}
									}
					}


					const swiperCases = new Swiper($swiperCases.find('.swiper-container'), orgOptions);

        /*
        |
        | Expertises toggle
        |-------------------
        */
        const event = isMobile ? 'click' : 'mouseover';

        $expertiseTitle.on(event, function () {
            const $item    = $(this);
            const target   = $item.data('target');
            const $target  = $(target);
            // const timeline = new TimelineMax({ 
            //     paused: true, 
            //     onStart: () => {
            //         $expertiseTitle.addClass('pe-none');
            //     },
            //     onComplete: () => {
            //         $expertiseTitle.removeClass('pe-none')
            //         app.updateLocomotiveScroll() 
            //     }
            // });

            const $active  = $('.item-expertise-content.active');

            if($(window).width() < 992){
                app.scrollTo('#end-col-left');
            }

            $expertiseTitle.removeClass('active')
            $item.addClass('active');

            $active.removeClass('active')
            $target.addClass('active');

            setTimeout(() => {
                app.updateLocomotiveScroll()
            }, 500);

            // timeline
            //     .to($active, 0.6, { x: 30, opacity: 0, ease: Power1.easeOut })
            //     .set($active, { className: '-=active' })
            //     .set($target, { className: '+=active' })
            //     .fromTo($target, 0.6, { x: 30, opacity: 0, ease: Power1.easeOut }, { x: 0, opacity: 1, ease: Power1.easeOut })
            
            // timeline.play()
        });


							        /*
        |
        | Swiper Poles
        |---------------------
        */
        const orgOptionsPoles = {
									slidesPerView: 4,
									speed: 1000,
									spaceBetween: 0,
									navigation: {
										prevEl: $('.swiper-poles-arrows').find('.arrow-left'),
										nextEl: $('.swiper-poles-arrows').find('.arrow-right')
									},   
									breakpoints: {
													1: {
																	slidesPerView: 1.2,
													},
													768: {
																	slidesPerView: 2,
													},
													992: {
																	slidesPerView: 4,
													},
													1200: {
																	slidesPerView: 4,
													}
									}
					}

					const swiperPoles = new Swiper($swiperPoles.find('.swiper-container'), orgOptionsPoles);
								
    }
}
