/*** IMPORTS FROM imports-loader ***/
var define = false;

import { TimelineMax } from "gsap";

export default {
    init: (app) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $loadMoreBtn       = $('.load-more-btn'),
            $postsContainer    = $('.loaded-posts'),
            $loadMoreContainer = $('.load-more-container'),
            $loadMore          = $('.load-more'),
            $loaderContainer   = $('.loader-container'),
            $blocFilters       = $('.bloc-filters'),
            $itemFilter        = $blocFilters.find('.item-filter'),
            $loaderFilter      = $('#loader-filter')
        ;
        
        /*
        |
        | Variables
        |------------
        */
        let 
            xhr   = null,
            state = {
                categoryId: 'all',
                categoryName: null
            }
        ;


        /*
        |
        | Filtering
        |-----------
        */
        $itemFilter.on('click', function(e){
            e.preventDefault();

            const $item = $(this);

            $itemFilter.removeClass('active');
            $item.addClass('active');

            state.categoryId = $item.data('category-id');
            state.categoryName = $item.find('.item-text').text();

            ajaxFilter();
        });


        function ajaxFilter(){
            if (xhr !== null) {
                xhr.abort()
            }

            const route = `/ajax/cases/category/${state.categoryId}`;

            xhr = $.ajax({
                url: route,
                type: 'GET',
                dataType: 'json',
                beforeSend: () => {
                    $postsContainer.fadeOut(400, function () {
                        $(this).html('');
                        $loaderFilter.fadeIn(400);
                    });
                },
                success: (response, status) => {
                    $postsContainer.html(response).show();
                    const tl = new TimelineMax({
                        paused: true,
                        onComplete: () => {
                            app.updateLocomotiveScroll()
                        }
                    })
                    tl.staggerFrom($postsContainer.find('.card-article.loaded'), 0.8, {
                        autoAlpha: 0,
                        y: 30,
                        ease: Power1.easeOut
                    }, 0.1);
                    tl.play();
                    $postsContainer.find('.card-article.loaded').removeClass('loaded')
                    $loaderFilter.hide();
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(response, status, error);
                }
            })
        }

    }
}
