/*** IMPORTS FROM imports-loader ***/
var define = false;

import { TimelineMax } from "gsap";
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

export default {
	init: (app, Menu, Kira, CustomEase, ScrollMagic, LocomotiveScroll, Loader, Swiper, Scrollbar) => {
		/*
		|
		| Constants
		|-----------
		*/
        const 
            $html                  = $('html'),
            $body                  = $('body'),
            $pageLoader            = $('#page-loader'),
            loaderAnimatedSvg      = document.querySelector('.page-loader .item-logo svg:nth-child(2)'),
            $loaderLogo            = $pageLoader.find('.item-logo'),
            $header                = $('#header'),
            $langSwitcher          = $('.lang-switcher'),
            $menuHasChildren       = $header.find('.menu-item-has-children'),
            scrollAreas            = document.querySelectorAll('.scroll-area'),
            $menuWrapper           = $('#mobile-menu'),
            $menuButton            = $('#header .btn-menu'),
            $menuBars              = $menuButton.find('.item-burger > span'),
            $menuClose             = $menuWrapper.find('.item-close'),
            $menuStaggerItems      = $menuWrapper.find('[data-stagger-item]'),
            $cardCaseStudy         = $('.card-case-study'),
            $sectionReferences     = $('#section-references'),
            $referencesList        = $sectionReferences.find('.references-list'),
            $referencesListItems   = $referencesList.find('.item-reference'),
            $blackHeaderPages      = $('#page-news, #page-references, #page-404, #page-cms, #page-cases-studies'),
            $swiperImages          = $('.swiper-images'),
            $sectionTestimonies    = $('#section-testimonies'),
            $swiperTestimonies     = $sectionTestimonies.find('.swiper-testimonies'),
            isIe                   = app.detectIE(),
            $scrollTo              = $('[data-custom-scroll-to]'),
            pageHasLocomotiveScroll = !$('#page-offre').length 
        ;
        

        /*
        |
        | Variables
        |------------
        */
        let
            locomotiveDirection = 'bottom',
            locomotiveScrollTop = 0
        ;


        /*
		|
		| Easings
		|----------
        */
        CustomEase.create("easeCustom", "M0,0 C0.4,0 0.2,1 1,1")
        CustomEase.create("easeSmooth", "M0,0 C0.19,1 0.22,1 1,1") 

        if (isIe === false){
            $body.addClass('notIE')
        } else {
             $body.addClass('isIE')
        }
		

		/*
        |
        | Scroll Areas
        |---------------
        */
		Array.prototype.forEach.call(scrollAreas, element => {
			Scrollbar.init(element);
        });
        
        
        const $memberPopin = $('#member-popin');
        const $cardMember = $('.card-member');
        const $memberPopinClose = $memberPopin.find('.item-close')

        let tlmemberPopin = null;
    

        $cardMember.on('click', function(){
            const $card      = $(this);
            const prototype  = $card.data('popin-prototype');
            const $prototype = $(prototype);

            $memberPopin.addClass('active');
            $memberPopin.find('.item-inner').html($prototype); 

            tlmemberPopin = new TimelineMax({ 
                paused: true,
                onReverseComplete: () => {
                    TweenMax.set($memberPopinClose, {clearProps: 'all'})
                    TweenMax.set($memberPopinClose.find('.item-line'), {clearProps: 'all'})
                    $memberPopin.removeClass('active')
                }
            })
            tlmemberPopin
                .to($memberPopin.find('.item-left'), 1.3, { scaleY: 1, ease: Expo.easeInOut }, 'start')
                .to($memberPopin.find('.item-right'), 1.3, { scaleY: 1, ease: Expo.easeInOut }, 'start')
                .to($memberPopin.find('.item-bg'), 2.4, { opacity: 1, scale: 1, ease: 'easeSmooth' }, 'after-=0.2')
                .staggerFrom($memberPopin.find('[data-stagger-item]'), 1, { opacity: 0, y: 30, scale: 1, ease: Power1.easeOut }, 0.1, 'after-=0.2')
                .from($memberPopinClose, 0.6, { opacity: 0, x: 50, ease: Power1.easeOut }, 'after-=0.2')
                .from($memberPopinClose.find('.item-line'), 0.4, { rotation: 0, ease: Power1.easeOut }, 'after+=0.2')

            

            tlmemberPopin.play().timeScale(1)
        });

        $memberPopinClose.on('click', () => {
            tlmemberPopin.reverse().timeScale(2);
        })

        
        /*
		|
		| Page transition
		|-----------------
        */
        // let url;
        // const transitionTl = new TimelineMax({ paused: true, onComplete: () => window.location.href = url });

        // transitionTl    
        //     .to('#site-container', 1, { y: -100, ease: Expo.easeIn }, 'start')
        //     .to('#layer-transition-1', 1, { scaleY: 1, transformOrigin: 'left bottom', ease: Expo.easeInOut }, 'start+=0.4');

        // $('a').on('click', function (e) {
        //     const $link = $(this);
        //     const href = $link.attr('href');
        //     const target = $link.attr('target');

        //     if (href && href != '#' && target != "_blank") {
        //         e.preventDefault();

        //         url = href;

        //         transitionTl.play();
        //     }
        // });


        /*
		|
		| Loader
		|---------
        */
        // if (sessionStorage.getItem('loaded_once') === null) {
        //     $pageLoader.addClass('active');
        // }

        if (sessionStorage.getItem('loaded_once') === null) {
            sessionStorage.setItem('loaded_once', 'loaded_once');
        }

        let loaderProgress = { percentage: 0 };

        const loader = new Loader($pageLoader, {
            useWindowLoad: false,
            onLoad: () => {
                app.dispachEvent($body, 'dom:ready');
            }
        });

        if ($pageLoader.hasClass('active')) {
            loader.loaderTimeline
                .to(loaderProgress, 1.6, { percentage: 100, ease: Power1.easeOut, onUpdate: () => {
                    loaderAnimatedSvg.style.clipPath = `polygon(0% 0%, ${loaderProgress.percentage}% 0%, ${loaderProgress.percentage}% 100%, 0% 100%)`;
                } }, 'start')
                .to($loaderLogo, 0.8, { y: -100, ease: Power1.easeOut }, 'logo-=0')
                .to($loaderLogo, 0.4, { opacity: 0, ease: Power1.easeOut }, 'logo-=0')
                .to($pageLoader.find('.layer.above'), 1.6, { scaleY: 0,  ease: Expo.easeInOut}, 'step2-=0.8')
                .to($pageLoader.find('.layer.behind'), 1.6, { scaleY: 0,  ease: Expo.easeInOut}, 'step2-=0.74')
                .addCallback(() => { app.dispachEvent($body, 'loader:end'); }, 'step2-=0.2')
        } else {
            loader.loaderTimeline
                .addCallback(() => { app.dispachEvent($body, 'loader:end'); }, 'start+=0.5')
        }

        loader.init();

        $(window).on('load', function(){
            if (pageHasLocomotiveScroll){
                app.updateLocomotiveScroll();
            }
           
        }) 


        /*
		|
		| Session storage
		|------------------
        */
        // if (sessionStorage.getItem('loaded_once') === null) {
        //     sessionStorage.setItem('loaded_once', 'loaded_once');
        // }

        
        /*
		|
		| Handle Loader End
		|--------------------
        */
        $body.on('dom:ready', () => {
            $html.addClass('loaded');

            if(pageHasLocomotiveScroll){
                window.locomotive = new LocomotiveScroll({
                    el: document.querySelector('.locomotive-scroll-container'),
                    smooth: true,
                    inertia: .7,
                    mouseMultiplier: 0.3,
                    getSpeed: true
                    //smoothMobile: true
                });
            }
            

            app.dispachEvent($body, 'locomotive:initialized');
        });

        /*
		|
		| Locomotive Scroll Calls
		|--------------------------
        */
        if (pageHasLocomotiveScroll){
            $body.on('locomotive:initialized', () => {
                /*
                |
                | On scroll
                |------------
                */
                window.locomotive.on('scroll', instance => {
                    const speed = instance.speed;
                    const scrollTop = instance.scroll.y;
                    locomotiveDirection = scrollTop > locomotiveScrollTop ? 'bottom' : 'top';
                    locomotiveScrollTop = scrollTop;

                    if (Math.abs(speed) > 0) {
                        if (!$html.hasClass('custom-has-scroll-scrolling')) {
                            $html.addClass('custom-has-scroll-scrolling');
                        }
                    } else {
                        $html.removeClass('custom-has-scroll-scrolling');
                    }
                });
            });
        }
        
        

        // window.locomotive = new LocomotiveScroll({
        //     el: document.querySelector('.locomotive-scroll-container'),
        //     smooth: true,
        //     inertia: .7,
        //     mouseMultiplier: 0.3,
        //     getSpeed: true
        //     //smoothMobile: true
        // });


        /*
        |
        | Black Header
        |---------------
        */
        if ($blackHeaderPages.length) {
            $header.addClass('header-black');
        }


        /*
		|
		| Menu
		|-------
        */
        const menu = new Menu($menuWrapper, $menuButton, {
            reverseTimeScale: 2
        });

        menu.menuTimeline
            .to($menuBars.eq(1), 0.3, { autoAlpha: 0 }, 'start')
			.to($menuBars.eq(0), 0.5, { x: 0, y: 8, rotation: 45, ease: Power1.easeOut }, 'start')
            .to($menuBars.eq(2), 0.5, { x: 0, y: -8, rotation: -45, ease: Power1.easeOut }, 'start')
            .to($menuWrapper, 0.5, { autoAlpha: 1, ease: Power1.easeOut }, 'start+=0.3')

        if ($blackHeaderPages.length) {
            menu.menuTimeline
                .to($header, 0.1, { className: '-=header-black' }, 'start+=0.3')
        }
        
        menu.menuTimeline
            .staggerFrom($menuStaggerItems, 0.6, { autoAlpha: 0, x: 20, ease: Power1.easeOut }, '0.1', '+=0')
        
        menu.init();

        $menuWrapper.on('menu:open', () => {
            app.stopLocomotiveScroll()
        })
        $menuWrapper.on('menu:closed', () => {
            app.startLocomotiveScroll()
        });

        $menuWrapper.find('.menu-item-has-children').on('click', function (e) {
            const $li = $(this);
            const $submenu = $li.find('> .submenu');

            $submenu.slideToggle(600)
        })

        $menuWrapper.find('.menu-item-has-children > a').on('click', function (e) {
            e.preventDefault();
        })

        /*
		|
		| Menu dropdown
		|----------------
        */
        $menuHasChildren.find('> a').on('click', e => e.preventDefault())
        $menuHasChildren.on('mouseover', function(){
            const $li = $(this);
            const $submenu = $li.find('.submenu');
            const timeline = new TimelineMax({ 
                paused: true,
                //onStart: () => { $submenu.addClass('active'); },
                //onReverseComplete: () => { $submenu.removeClass('active'); }
            });

            $submenu.addClass('active');
            timeline
                .to($submenu, 1, { scaleY: 1, ease: Expo.easeInOut }, 'start')
                .to($header, 0.1, { className: '+=header-black' }, 'start+=0.4')
                .staggerTo($submenu.find('li'), 0.5, { x: 0, opacity: 1, ease: Power1.easeOut }, 0.1, '-=0.4');

            if (!app.isDefined($submenu[0]['timeline'])){
                $submenu[0]['timeline'] = timeline
            }

            $submenu[0]['timeline'].play().timeScale(1);

        }).on('mouseleave', function () {
            const $submenu = $(this).find('.submenu');
            $submenu.removeClass('active');
            $submenu[0]['timeline'].reverse().timeScale(5);
        });


        $('[data-color-start]').on('mouseover', function () {
            const $item = $(this);
            const color1 = $item.data('color-start');
            const color2 = $item.data('color-end');
            const $submenuContent = $item.closest('.submenu-content');

            $submenuContent.css('background-image', `linear-gradient(to right, ${color1},  ${color2})`)

        })
        
        $menuHasChildren.on('mouseleave', function () {
            const $item = $(this);
            const $submenuContent = $item.find('.submenu-content');

            $submenuContent.css('background-image', `linear-gradient(to right, #E51677,  #781E82)`)

        });


        /*
		|
		| Lang switcher
		|----------------
        */
        const langTl = new TimelineMax({ 
            paused: true,
            onStart: () => { $langSwitcher.find('ul').addClass('active'); },
            onReverseComplete: () => { $langSwitcher.find('ul').removeClass('active'); }
        });
        langTl.staggerTo($langSwitcher.find('li'), 0.5, { x: 0, opacity: 1, ease: Power1.easeOut }, 0.1);

        $langSwitcher.find('.current').on('click', function(){
            if (langTl.totalProgress() > 0){
               langTl.reverse().timeScale(1.5);
            } else {
                langTl.play().timeScale(1);
            }
        });


        /*
		|
		| Card case study hover
		|------------------------
        */
        if ($cardCaseStudy.length){
            $body.on('loader:end', () => {
                let tos = {};

                $.each($cardCaseStudy, function(k, v){
                    const $card = $(this);
                    const $title = $card.find('.item-title');
                    const $text = $card.find('.item-text');
                    const height = $(window).width() > 767 ? $text.outerHeight() : 0;
                    const toKey = `case-${k}`;

                    tos[toKey] = null;

                    const tl = new TimelineMax({ paused: true });
                    
                    tl.from($title, 0.5, { y: height, ease: Power1.easeOut }, 'start') 
                    tl.from($text, 0.5, { x: 20, opacity: 0, ease: Sine.easeOut }, '-=0.3') 

                    $card
                        .on('mouseover', () => tl.play())
                        .on('mouseleave', () => tl.reverse())

                    $(window).on('resize', function(){
                        if (tos[toKey] !== null){
                            clearTimeout(tos[toKey])
                        }
                        
                        tos[toKey] = setTimeout(() => {
                            const height = $(window).width() > 767 ? $text.outerHeight() : 0;
                            TweenMax.set($title, { clearProps: 'all' })
                            TweenMax.set($text, { clearProps: 'all' })
                            tl.clear()
                            tl.from($title, 0.5, { y: height, ease: Power1.easeOut }) 
                            tl.from($text, 0.5, { x: 20, opacity: 0, ease: Sine.easeOut }, '-=0.3') 

                        }, 500);
                    })
                });
            })
            
            
        }


        /*
        |
        | References défilement
        |------------------------
        */
        if ($referencesList.length){

            const total = $referencesListItems.length;
            const controller = new ScrollMagic.Controller();
            const scene = new ScrollMagic.Scene({
                'triggerElement': $referencesList,
                'triggerHook': 1,
                'reverse': false
            });
            const timelineReferences = new TimelineMax({ paused: true, onComplete: function(){ this.restart(); } });
            const duration = total * 2.5;

            
            timelineReferences.to($referencesList.find('.item-inner'), duration, { x: -$referencesList.find('.item-inner').width()/2, ease: Power0.easeNone })

            if ($referencesList.hasClass('sliding')){
                scene
                    .on('enter', () => {
                        timelineReferences.play()
                    })
                    .addTo(controller)
            }
        }

        /*
        |
        | Swiper Images
        |--------------------
        */
        const $swiperReferences = $('.swiper-references');

        if ($swiperReferences.length){
            $.each($swiperReferences, function(){
                const $swiper = $(this);
                const $swiperContainer = $swiper.find('.swiper-container');
                const $swiperWrapper = $swiper.find('.swiper-wrapper');

                const swiper = new Swiper($swiperContainer, {
                    loop: true,
                    mousewheel: {
                        forceToAxis: true
                    },
                    autoplay: {
                        delay: 1500,
                    },
                    slidesPerView: 'auto',
                    speed: 600,
                    spaceBetween: 100,
                    centeredSlides: true
                });

                // $swiperContainer.on('mouseover', function(){
                //     swiper.autoplay.stop();
                //     $swiperWrapper.addClass('speed')
                // }).on('mouseleave', function () {
                //     swiper.autoplay.start();
                //     $swiperWrapper.removeClass('speed')
                // })
            })
        }
        


        /*
        |
        | Swiper Images
        |--------------------
        */
        const swiperImages = new Swiper($swiperImages.find('.swiper-container'), {
            slidesPerView: 1.2,
            speed: 1000,
            spaceBetween: 30,
            centeredSlides: true,
            pagination: {
                el: '.swiper-images-pagination',
                type: 'bullets',
                clickable: true
            },
            breakpoints: {
                1: {
                    slidesPerView: 1.2,
                },
                992: {
                    slidesPerView: 1.2,
                },
                1400: {
                    slidesPerView: 1.5,
                }
            }
        });

        if ($swiperImages.find('.swiper-slide').length > 2) {
            swiperImages.slideNext()
        }

        var initPhotoSwipeFromDOM = function(gallerySelector) {

            // parse slide data (url, title, size ...) from DOM elements 
            // (children of gallerySelector)
            var parseThumbnailElements = function(el) {
                var thumbElements = el.childNodes,
                    numNodes = thumbElements.length,
                    items = [],
                    figureEl,
                    linkEl,
                    size,
                    item;

                for(var i = 0; i < numNodes; i++) {

                    figureEl = thumbElements[i]; // <figure> element

                    // include only element nodes 
                    if(figureEl.nodeType !== 1) {
                        continue;
                    }

                    linkEl = figureEl.children[0]; // <a> element
                    console.log(linkEl);
                    size = linkEl.getAttribute('data-size').split('x');

                    // create slide object

                    if ( 'video' == linkEl.getAttribute( 'data-type' ) ) {
                        item = {
                            html: linkEl.getAttribute( 'data-video' )
                        }
                    } else {
                        item = {
                            src: linkEl.getAttribute('href'),
                            w: parseInt(size[0], 10),
                            h: parseInt(size[1], 10)
                        };

                    }

                    if(figureEl.children.length > 1) {
                        // <figcaption> content
                        item.title = figureEl.children[1].innerHTML; 
                    }

                    if(linkEl.children.length > 0) {
                        // <img> thumbnail element, retrieving thumbnail url
                        item.msrc = linkEl.children[0].getAttribute('src');
                    } 

                    item.el = figureEl; // save link to element for getThumbBoundsFn
                    items.push(item);
                }

                return items;
            };

            // find nearest parent element
            var closest = function closest(el, fn) {
                return el && ( fn(el) ? el : closest(el.parentNode, fn) );
            };

            // triggers when user clicks on thumbnail
            var onThumbnailsClick = function(e) {
                e = e || window.event;
                e.preventDefault ? e.preventDefault() : e.returnValue = false;

                var eTarget = e.target || e.srcElement;

                // find root element of slide
                var clickedListItem = closest(eTarget, function(el) {
                    return (el.classList.contains('swiper-slide'));
                });

                if(!clickedListItem) {
                    return;
                }

                // find index of clicked item by looping through all child nodes
                // alternatively, you may define index via data- attribute
                var clickedGallery = clickedListItem.parentNode,
                    childNodes = clickedListItem.parentNode.childNodes,
                    numChildNodes = childNodes.length,
                    nodeIndex = 0,
                    index;

                for (var i = 0; i < numChildNodes; i++) {
                    if(childNodes[i].nodeType !== 1) { 
                        continue; 
                    }

                    if(childNodes[i] === clickedListItem) {
                        index = nodeIndex;
                        break;
                    }
                    nodeIndex++;
                }



                if(index >= 0) {
                    // open PhotoSwipe if valid index found
                    openPhotoSwipe( index, clickedGallery );
                }
                return false;
            };

            // parse picture index and gallery index from URL (#&pid=1&gid=2)
            var photoswipeParseHash = function() {
                var hash = window.location.hash.substring(1),
                params = {};

                if(hash.length < 5) {
                    return params;
                }

                var vars = hash.split('&');
                for (var i = 0; i < vars.length; i++) {
                    if(!vars[i]) {
                        continue;
                    }
                    var pair = vars[i].split('=');  
                    if(pair.length < 2) {
                        continue;
                    }           
                    params[pair[0]] = pair[1];
                }

                if(params.gid) {
                    params.gid = parseInt(params.gid, 10);
                }

                return params;
            };

            var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
                var pswpElement = document.querySelectorAll('.pswp')[0],
                    gallery,
                    options,
                    items;

                items = parseThumbnailElements(galleryElement);

                // define options (if needed)
                options = {

                    bgOpacity: .8,

                    // define gallery index (for URL)
                    galleryUID: galleryElement.getAttribute('data-pswp-uid'),

                    getThumbBoundsFn: function(index) {
                        // See Options -> getThumbBoundsFn section of documentation for more info
                        var thumbnail = items[index].el.getElementsByTagName('div')[0], // find thumbnail
                            pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                            rect = thumbnail.getBoundingClientRect(); 

                        return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
                    }

                };

                // PhotoSwipe opened from URL
                if(fromURL) {
                    if(options.galleryPIDs) {
                        // parse real index when custom PIDs are used 
                        // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                        for(var j = 0; j < items.length; j++) {
                            if(items[j].pid == index) {
                                options.index = j;
                                break;
                            }
                        }
                    } else {
                        // in URL indexes start from 1
                        options.index = parseInt(index, 10) - 1;
                    }
                } else {
                    options.index = parseInt(index, 10);
                }

                // exit if index not found
                if( isNaN(options.index) ) {
                    return;
                }

                if(disableAnimation) {
                    options.showAnimationDuration = 0;
                }

                // Pass data to PhotoSwipe and initialize it
                gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
                gallery.init();

                gallery.listen('beforeChange', function() {
                  var currItem = $(gallery.currItem.container);
                  $('.pswp__video').removeClass('active');
                  var currItemIframe = currItem.find('.pswp__video').addClass('active');
                  $('.pswp__video').each(function() {
                    if (!$(this).hasClass('active')) {
                      $(this).attr('src', $(this).attr('src'));
                    }
                  });
                });

                gallery.listen('close', function() {
                  $('.pswp__video').each(function() {
                    $(this).attr('src', $(this).attr('src'));
                  });
                });
            };

            // loop through all gallery elements and bind events
            var galleryElements = document.querySelectorAll( gallerySelector );

            for(var i = 0, l = galleryElements.length; i < l; i++) {
                galleryElements[i].setAttribute('data-pswp-uid', i+1);
                galleryElements[i].onclick = onThumbnailsClick;
            }

            // Parse URL and open gallery if it contains #&pid=3&gid=1
            var hashData = photoswipeParseHash();
            if(hashData.pid && hashData.gid) {
                openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
            }
        };

        // execute above function
        initPhotoSwipeFromDOM('.swiper-wrapper-photoswipe');


        /*
        |
        | Swiper Testimonies
        |---------------------
        */
        const swiperTestimonies = new Swiper($swiperTestimonies.find('.swiper-container'), {
            slidesPerView: 2.6,
            speed: 1000,
            spaceBetween: 160,
            centeredSlides: true,
            autoplay: {
                delay: 5000,
            },
            pagination: {
                el: '.swiper-testimonies-pagination',
                type: 'bullets',
                clickable: true
            },
            breakpoints: {
                1: {
                    slidesPerView: 1.3,
                    spaceBetween: 60,
                },
                768: {
                    slidesPerView: 1.6,
                    spaceBetween: 80,
                },
                992: {
                    slidesPerView: 2,
                    spaceBetween: 100,
                },
                1200: {
                    slidesPerView: 2.6,
                    spaceBetween: 160,
                }
            }
        });


        if ($swiperTestimonies.find('.swiper-slide').length > 2) {
            //swiperTestimonies.slideNext()
        }


        /*
		|
		| Scroll To
		|------------
        */
        $scrollTo.on('click', function (e) {
            e.preventDefault();

            const target = $(this).data('custom-scroll-to');
            const $target = $(target);

            if ($target.length) {
                app.scrollTo(target)
            }
        });


        /*
		|
		| Popup projects
		|-----------------
        */
        const $formPopup = $('.popup-form');
        const $formPopupBtn = $('.btn-popup-form');

        if ($formPopup.length){
            const popupTimeline = new TimelineMax({ 
                paused: true,
                onReverseComplete: () => {
                    app.startLocomotiveScroll();
                    $formPopup.removeClass('active');
                }
            });

            popupTimeline
                .from($formPopup.find('.item-overlay'), 0.3, { opacity: 0, ease: Power1.easeOut }, 'start')
                .from($formPopup.find('.item-close'), 0.3, { opacity: 0, ease: Power1.easeOut }, 'start+=0.2')
                .from($formPopup.find('.item-wrapper'), 1.1, { scaleY: 0, ease: Expo.easeInOut }, '-=0.2')
                .from($formPopup.find('.item-container'), 0.8, { opacity: 0, y: 50, ease: Power1.easeOut }, '-=0.3')

            $formPopupBtn.on('click', () => {
                app.stopLocomotiveScroll();
                $formPopup.addClass('active');
                popupTimeline.play();
            });

            $formPopup.find('.item-close, .item-overlay').on('click', () => {
                popupTimeline.reverse().timeScale(1.5);
            });
        }
        


        /*
		|
		| Kira
		|-------
        */
        const kira = new Kira(ScrollMagic, {
            debug: false,
            loadEvent: {
                domElement: $body,
                eventName: 'loader:end'
            },
            optimize: true,
            options: {
                start: '-=0.6',
                triggerHook: 'onEnter'
            }
        });

        /*
		| fadeInUp.parallax.sm
		|-----------------------
        */
        kira.add('fadeInUp', ($item, timeline, start) => {
            timeline.from($item, 0.8, { y: 30, autoAlpha: 0, ease: Power1.easeOut }, start)
        });
        
        /*
		| fadeInUp.parallax.sm
		|-----------------------
        */
        kira.add('fadeInUp.parallax.sm', ($item, timeline, start) => {
            timeline.from($item, 0.8, { y: 100, autoAlpha: 0, ease: Sine.easeOut }, start)
        });

        /*
		| fadeInUp.parallax
		|--------------------
        */
        kira.add('fadeInUp.parallax', ($item, timeline, start) => {
            timeline.from($item, 1, { y: 80, autoAlpha: 0, ease: Power1.easeOut }, start)
        });

        /*
		| fadeInUp.parallax.sm
		|-----------------------
        */
        kira.add('fadeInLeft.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFrom($item.find('[data-stagger-item]'), 0.6, { autoAlpha: 0, x: 20, ease: Power1.easeOut }, '0.1', start)
        });

        kira.add('fadeInUp.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFrom($item.find('[data-stagger-item]'), 0.6, { autoAlpha: 0, y: 20, ease: Power1.easeOut }, '0.1', start)
        });

        /*
		| fadeInUp.parallax
		|--------------------
        */
        kira.add('scaleX', ($item, timeline, start) => {
            timeline.from($item, 1, { scaleX: 0, transformOrigin: 'left top', ease: Expo.easeInOut }, start)
        });

        kira.init();
	}
}
