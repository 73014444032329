/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper, customGoogleMap) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $sectionReasons     = $('#section-reasons'),
            $swiperReasons      = $sectionReasons.find('.swiper-reasons'),
            $map                = $('#partners-map'),
            $sectionPartners    = $('#section-partners'),
            $swiperPartners     = $sectionPartners.find('.swiper-partners'),
            $sectionOrganizations = $('#section-organizations'),
            $swiperOrganizations  = $sectionOrganizations.find('.swiper-organizations')
        ;


        /*
        |
        | Swiper Reasons
        |-----------------
        */
        const swiperReasons = new Swiper($swiperReasons.find('.swiper-container'), {
            slidesPerView: 1,
            speed: 1000,
            spaceBetween: 0,
            pagination: {
                el: '.swiper-reasons-pagination',
                type: 'bullets',
                clickable: true
            }
        });


        $sectionReasons.on('click', function(e){
            if (e.pageX < $(window).width()/2 ){
                swiperReasons.slidePrev(1000)
            } else {
                swiperReasons.slideNext(1000)
            }
        });


        /*
		|
		| initGoogleMap
		|----------------
		|
        */
        const toHide = document.querySelector('.map-hide')

        $.each($map, function () {
            var $map = $(this);
            console.log($('.marker').length)
            var map = new customGoogleMap($map, {
                'markers': $('.marker'),
                'zoom': 4,
                'fitBoundsZoom': 3,
                'customInfoWindow': true,
                'scrollToCard': true,
                'disableDefaultUI': true,
                'onMarkerClicked': function () {
                    toHide.classList.add('active');

                    if($(window).width() < 1200){
                        app.scrollTo(document.querySelector('.trigger-marker'))
                    }
                    setTimeout(() => {
                        
                    }, 1000);
                    app.updateLocomotiveScroll()
                }
            });
        });


        /*
        |
        | Swiper Partners
        |------------------
        */
        const swiperPartners = new Swiper($swiperPartners.find('.swiper-container'), {
            slidesPerView: 1,
            speed: 1000,
            spaceBetween: 60,
            navigation: {
                prevEl: $('.swiper-partners-arrows').find('.arrow-left'),
                nextEl: $('.swiper-partners-arrows').find('.arrow-right')
            },
            breakpoints: {
                1: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                },
                520: {
                    slidesPerView: 1,
                    spaceBetween: 80,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 80,
                },
                992: {
                    slidesPerView: 2,
                    spaceBetween: 80,
                },
                1400: {
                    slidesPerView: 3,
                    spaceBetween: 100,
                }
                
            }
        });


        /*
        |
        | Swiper Organizations
        |---------------------
        */
        const orgOptions = {
            slidesPerView: 4,
            speed: 1000,
            spaceBetween: 0,
            // pagination: {
            //     el: '.swiper-organizations-pagination',
            //     type: 'progressbar',
            //     clickable: true
            // },
            breakpoints: {
                1: {
                    slidesPerView: 1.2,
                },
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 4,
                }
            }
        }

        if ($swiperOrganizations.find('.swiper-slide').length > 4) {
            orgOptions.scrollbar = {
                el: '.swiper-organizations-pagination',
                draggable: true
            }
        }

        const swiperOrganizations = new Swiper($swiperOrganizations.find('.swiper-container'), orgOptions);
        
    }
}
