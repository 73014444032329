/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| JQuery replaceClass
|----------------------
*/
$.fn.replaceClass = function (oldClasses, newClasses) {
    return this.each(function () {
        $(this).removeClass(oldClasses).addClass(newClasses);
    })
}

/*
|
| Dump
|--------
*/
export function dump(value){
  console.log(value);
}

/*
|
| Dispatch event
|-----------------
*/
export function dispachEvent($element, eventName, datas = null){
	var event = $.Event(eventName);

	if(datas !== null){
		for(let [key, value] of Object.entries(datas)){
			event[key] = value
		}
	}

	$element.trigger(event);
}

/*
|
| Check defined
|----------------
*/
export function isDefined(item) {
    return typeof item !== 'undefined';
}

/*
|
| Check exist
|-------------------
*/
export function exist(element) {
    return element.length;
}


/*
|
| Email validation
|-------------------
*/
export function isValidEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


/*
|
| Set cookie 
|------------
*/
export function setCookie(name, value, nbDays) {
    const date = new Date();
    date.setTime(date.getTime() + (nbDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}


/*
|
| Get cookie
|-------------------
*/
export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }

    return null;
}

export function deleteCookie(name) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function detectIE() {
    var ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}


/*
|
| LocomotiveScroll
|-------------------
*/
export function updateLocomotiveScroll() {
    if (window.locomotive != null) {
        window.locomotive.update();
    }
}

export function stopLocomotiveScroll() {
    if (window.locomotive != null) {
        window.locomotive.stop();
    }
}

export function startLocomotiveScroll() {
    if (window.locomotive != null) {
        window.locomotive.start();
    }
}

export function scrollTo(target, offset) {
    if (window.locomotive != null) {
        console.log(window.locomotive.scrollTo)
        window.locomotive.scrollTo(target, offset);
    }
}
