/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $offersContainer = $('.loaded-offers'),
            $blocFilters     = $('.item-filters'),
            $itemFilter      = $blocFilters.find('.item-filter'),
            $loaderFilter    = $('#loader-offers'),
            $sectionOffers   = $('#section-offers'),
            $itemOffer       = $sectionOffers.find('.item-offer .item-tab')
        ;

        
        /*
        |
        | Variables
        |------------
        */
        let 
            xhr   = null,
            state = {
                localisationId: null,
                localisationName: null
            }
        ;


        /*
        |
        | Tab
        |------
        */
        $('body').on('click', '#section-offers .item-offer .item-tab', function () {
            const $tab = $(this);
            const $item = $tab.closest('.item-offer');
            const $target = $item.find('.tab-content');

            $item.toggleClass('active');
            $target.slideToggle(800, function () {
                app.updateLocomotiveScroll()
            });
        });


        /*
        |
        | Filtering
        |-----------
        */
        $itemFilter.on('click', function(e){
            e.preventDefault();

            const $item = $(this);

            $itemFilter.removeClass('active');
            $item.addClass('active');

            state.localisationId = $item.data('term-id');
            state.localisationName = $item.text();

            ajaxFilter();
        });


        function ajaxFilter(){
            if (xhr !== null) {
                xhr.abort()
            }

            const route = `/ajax/offers/localisation/${state.localisationId}/${state.localisationName}`;

            xhr = $.ajax({
                url: route,
                type: 'GET',
                dataType: 'json',
                beforeSend: () => {
                    $offersContainer.fadeOut(400, function () {
                        $(this).html('');
                        $loaderFilter.fadeIn(400);
                    });
                },
                success: (response, status) => {
                    $offersContainer.html(response).show();
                    const tl = new TimelineMax({
                        paused: true,
                        onComplete: () => {
                            app.updateLocomotiveScroll()
                        }
                    })
                    tl.staggerFrom($offersContainer.find('.item-offer.loaded'), 0.8, {
                        autoAlpha: 0,
                        y: 30,
                        ease: Power1.easeOut
                    }, 0.1);
                    tl.play();
                    $offersContainer.find('.item-offer.loaded').removeClass('loaded')
                    $loaderFilter.hide();
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(response, status, error);
                }
            })
        }
    }
}
