/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
import Swiper from 'swiper/js/swiper.min';
import { TweenMax } from "gsap/TweenMax";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import CustomEase from "@lib/gsap-pro/CustomEase";
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import ScrollMagic from 'scrollmagic';
import LocomotiveScroll from '@lib/locomotive-scroll/src/locomotive-scroll';
import Scrollbar from 'smooth-scrollbar';

/*
|
| Importing Components
|-----------------------
*/
import CookieManager from '@components/cookie-manager';
import customGoogleMap from '@components/custom-google-map.js';
import MaterializeForm from '@components/materialize-form.js';
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';
import Loader from '@components/loader.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import home from '@pages/home.js';
import pole from '@pages/pole.js';
import agence from '@pages/agence.js';
import news from '@pages/news.js';
import cases from '@pages/cases.js';
import references from '@pages/references.js';
import offers from '@pages/offers.js';
import animations from '@pages/animations.js';
import contact from '@pages/contact.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        'file': animations,
        'dependencies': [app, Menu, Kira, CustomEase, ScrollMagic, LocomotiveScroll, Loader, Swiper, Scrollbar]
    },
	{
		'file': main, 
		'dependencies': [app, CookieManager]
    },
    {
        'file': home,
        'dependencies': [app, Swiper],
        'resolve': '#page-home'
    },
    {
        'file': pole,
        'dependencies': [app, Swiper, customGoogleMap],
        'resolve': '#page-pole'
    },
    {
        'file': agence,
        'dependencies': [app, Swiper, customGoogleMap],
        'resolve': '#page-agence'
    },
    {
		'file': news, 
		'dependencies': [app],
		'resolve': '#page-news'
    },
    {
        'file': cases,
        'dependencies': [app],
        'resolve': '#page-cases-studies'
    },
    {
        'file': references,
        'dependencies': [app],
        'resolve': '#page-references'
    },
    {
        'file': offers,
        'dependencies': [app],
        'resolve': '#page-nous-rejoindre'
    },
    {
        'file': contact,
        'dependencies': [app, MaterializeForm, customGoogleMap],
        'resolve': '#page-contact'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

